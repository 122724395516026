import React from "react";
import {
  IResourceComponentsProps,
  useApiUrl,
  useNavigation,
} from "@refinedev/core";
import { Edit, useForm, useSelect, getValueFromEvent } from "@refinedev/antd";
import { Form, Input, Select, DatePicker, Upload, Button } from "antd";
import dayjs from "dayjs";
import { ICourse } from "../../interfaces";
import { handleVideoUpload } from "../../utils/handleVideoUpload";
import { UploadOutlined } from "@ant-design/icons";

export const UnitEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, onFinish } = useForm();

  const { selectProps: coursesSelectProps } = useSelect({
    resource: "courses/autocomplete",
    optionLabel: "label",
    optionValue: "value",

    pagination: {
      mode: "server",
    },
  });

  const { list } = useNavigation();
  const apiUrl = useApiUrl();

  const handleOnFinish = async (values: any) => {
    console.log("values", values);

    // await onFinish({
    //   ...values,
    //   video: values.video ? values.video[0].response : "",
    // });
    // list("courses/units");
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" onFinish={handleOnFinish}>
        <Form.Item
          label="Course"
          name={["course", "title"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...coursesSelectProps} />
        </Form.Item>
        <Form.Item
          label="Title"
          name={["title"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={["video"]}
          valuePropName="video"
          getValueFromEvent={getValueFromEvent}
          noStyle
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Upload.Dragger
            name="video"
            action={`${apiUrl}/storage/upload/video`}
            listType="picture"
            className="upload-list-inline"
            customRequest={handleVideoUpload}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Upload Video</Button>
          </Upload.Dragger>
        </Form.Item>
      </Form>
    </Edit>
  );
};
