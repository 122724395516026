import React from "react";
import { IResourceComponentsProps, BaseRecord, useMany } from "@refinedev/core";
import { useTable, List, EditButton, ShowButton, DateField, DeleteButton } from "@refinedev/antd";
import { Table, Space, Tooltip } from "antd";

export const BookingList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true
  });

  //   const { data: scheduleData, isLoading: scheduleIsLoading } = useMany({
  //     resource: "schedules",
  //     ids: tableProps?.dataSource?.map((item) => item?.schedule) ?? [],
  //     queryOptions: {
  //       enabled: !!tableProps?.dataSource,
  //     },
  //   });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex={["schedule"]} title="Schedule" />
        <Table.Column
          dataIndex="client"
          title="Client"
          render={(value: any) => (
            <>{value?.firstName + " " + value?.lastName}</>
          )}
        />
        <Table.Column
          dataIndex="provider"
          title="Provider"
          render={(value: any) => (
            <>{value?.firstName + " " + value?.lastName}</>
          )}
        />
        <Table.Column dataIndex={["service", "title"]} title="Service" />
        <Table.Column dataIndex="bookingType" title="Booking Type" />
        <Table.Column dataIndex="status" title="Status" />
        <Table.Column dataIndex="requirements" title="Requirements" />
        <Table.Column
          dataIndex={["createdAt"]}
          title="Created At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex={["updatedAt"]}
          title="Updated At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column dataIndex="__v" title="  V" />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              {/* <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} /> */}
              <Tooltip title="Delete">
              <DeleteButton hideText size="small" recordItemId={record._id} />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
