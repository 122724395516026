import React from "react";
import { IResourceComponentsProps, useList, useShow } from "@refinedev/core";
import { Show, TagField, BooleanField, TextField, ImageField } from "@refinedev/antd";
import { Typography, Tag } from "antd";

const { Title } = Typography;

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const { data: employersData, isLoading: employersIsLoading } = useList({
    resource: `referrals/employers`,

    filters: [
      {
        field: "serviceProvider",
        operator: "eq",
        value: data?.data?._id,
      },
    ]
  });

  const record = data?.data;

  const employers = employersData?.data[0];

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Role</Title>
      <TextField value={record?.role.title} />
      {record?.isInvite && (
        <>
          <Title level={5}>Invite</Title>
          <Tag color="#87d068">Invite</Tag>
        </>
      )}
      <Title level={5}>Profile</Title>
      <ImageField value={record?.avatar} width={200} />
      <Title level={5}>First Name</Title>
      <TextField value={record?.firstName} />
      <Title level={5}>Last Name</Title>
      <TextField value={record?.lastName} />
      <Title level={5}>Email</Title>
      <TextField value={record?.email} />
      <Title level={5}>Email Verified</Title>
      {record?.emailVerified ? (
        <Tag color="#87d068">Verified</Tag>
      ) : (
        <Tag color="#F50">Unverified</Tag>
      )}
      <Title level={5}>Phone</Title>
      <TextField value={record?.phone} />
      <Title level={5}>Phone Verified</Title>
      {record?.phoneVerified ? (
        <Tag color="#87d068">Verified</Tag>
      ) : (
        <Tag color="#F50">Unverified</Tag>
      )}
      <Title level={5}>Bio</Title>
      <TextField value={record?.bio} />
      {record?.role.title === "serviceProvider" && (
        <>
          <Title level={5}>Skills</Title>
          {record?.skills?.map((value: any) => (
            <>
              <TagField value={value.title} key={value._id} />
            </>
          ))}
        </>
      )}
      <Title level={5}>Date of Birth</Title>
      <TextField value={record?.dob} />
      {record?.role.title === "serviceProvider" && (
        <>
          <Title level={5}>Level of Education</Title>
        </>
      )}
      <TextField value={record?.educationLevel} />
      <Title level={5}>Gender</Title>
      <TextField value={record?.gender} />
      <Title level={5}>ID Number</Title>
      <TextField value={record?.idNo} />
      {record?.role.title === "serviceProvider" && (
        <>
          <Title level={5}>ID Front</Title>
          <ImageField value={record?.idFront} width={200} />
          <Title level={5}>ID Back</Title>
          <ImageField value={record?.idBack} width={200} />
          <Title level={5}>Certificate of Good Conduct</Title>
          <ImageField value={record?.conductCert} width={200} />
          <Title level={5}>Previous Training</Title>
          <TextField value={record?.previousTraining} />
          <Title level={5}>Work Arrangement</Title>
          <TextField value={record?.workArrangement} />
          <Title level={5}>Years of Experience</Title>
          <TextField value={record?.yearsOfExperience} />
          <Title level={5}>Previous Employer</Title>
          <TextField value={employers?.prevEmployerName} />
          <Title level={5}>Previous Employer Phone</Title>
          <TextField value={employers?.prevEmployerPhone} />
          <Title level={5}>Previous Job Title</Title>
          <TextField value={employers?.prevJobTitle} />
          <Title level={5}>Previous Job Description</Title>
          <TextField value={employers?.prevJobDescription} />
          <Title level={5}>Previous Salary</Title>
          <TextField value={employers?.prevSalary} />
          <Title level={5}>Previous Job duration</Title>
          <TextField value={employers?.prevWorkDuration} />
        </>
      )}
    </Show>
  );
};
