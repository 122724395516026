import React from "react";
import { IResourceComponentsProps, useApiUrl } from "@refinedev/core";
import { Edit, useForm, useSelect, getValueFromEvent } from "@refinedev/antd";
import { Form, Input, Select, Upload, Button } from "antd";
import { IFee, IServiceCategory } from "interfaces";
import { handleImageUpload } from "../../utils/handleImageUpload";
import { UploadOutlined } from "@ant-design/icons";

export const ServiceEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm();

  const servicesData = queryResult?.data?.data;
  const apiUrl = useApiUrl();

  const { selectProps: categorySelectProps } = useSelect({
    resource: "services/categories/autocomplete",
    optionLabel: "label",
    optionValue: "value",

    pagination: {
      mode: "server"
    }
  });

  const { selectProps: feesSelectProps } = useSelect({
    resource: "fees/autocomplete",
    optionLabel: "label",
    optionValue: "value",

    pagination: {
      mode: "server"
    }
  });

  const handleOnFinish = (values: any) => {
    onFinish({
      ...values,
      banner: values.banner ? values.banner[0].response : "",
      serviceCategory: values.serviceCategory.map((item: any) => {
        if (typeof item === "string") {
          return item;
        }
        return item.value;
      }),
      serviceFee:
        typeof values.serviceFee === "string"
          ? values.serviceFee
          : values.serviceFee.value,
    });
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={handleOnFinish}
        initialValues={{
          ...servicesData,
          serviceCategory: servicesData?.serviceCategory.map((item: any) => {
            return {
              label: item.title,
              value: item._id,
            };
          }),
          serviceFee: {
            label: servicesData?.serviceFee.title,
            value: servicesData?.serviceFee._id,
          },
        }}
      >
        <Form.Item label="Banner">
          <Form.Item
            name="banner"
            valuePropName="banner"
            getValueFromEvent={getValueFromEvent}
            noStyle
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Upload.Dragger
              name="banner"
              action={`${apiUrl}/storage/upload/image`}
              listType="picture"
              className="upload-list-inline"
              customRequest={handleImageUpload}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Upload banner</Button>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="Category"
          name={["serviceCategory"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...categorySelectProps} mode={"multiple"} />
        </Form.Item>
        <Form.Item
          label="Title"
          name={["title"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name={["description"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Requirements"
          name={["requirements"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Fee"
          name={["serviceFee"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...feesSelectProps} />
        </Form.Item>
        <Form.Item
          label="Min Salary"
          name={["minSalary"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Salary Range"
          name={["salaryRange"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  );
};
