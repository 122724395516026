import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, DatePicker, Select, Checkbox } from "antd";
import dayjs from "dayjs";
import { IFee } from "../../interfaces";

export const CourseEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const coursesData = queryResult?.data?.data;

  const { selectProps: feesSelectProps } = useSelect({
    resource: "fees/autocomplete",
    optionLabel: "label",
    optionValue: "value",

    pagination: {
      mode: "server"
    }
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Title"
          name={["title"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea autoSize />
        </Form.Item>
        <Form.Item
          label="Type"
          name={["type"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              { label: "Mentorship", value: "Mentorship" },
              { label: "Training", value: "Training" },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Fee"
          name={["fee", "title"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...feesSelectProps} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
