import React from "react";
import { IResourceComponentsProps, BaseRecord } from "@refinedev/core";
import { useTable, List, EditButton, ShowButton, DateField, TagField, RefreshButton } from "@refinedev/antd";
import { Table, Space } from "antd";

export const RoleList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true
  });

  const renderPermissions = (_: any, record: BaseRecord) => {
    return (
      <Space>
        {record.permissions.map((permission: any) => (
          <TagField value={`${permission.subject}:${permission.action}`} />
        ))}
      </Space>
    );
  };

  return (
    <List
      headerProps={{
        extra: (
          <Space>
            <RefreshButton />
          </Space>
        ),
      }}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="title" title="Title" />
        <Table.Column
          dataIndex="permissions"
          title="Permissions"
          render={renderPermissions}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          // render={(_, record: BaseRecord) => (
          //   <Space>
          //     <ShowButton hideText size="small" recordItemId={record._id} />
          //     <EditButton hideText size="small" recordItemId={record._id} />
          //   </Space>
          // )}
        />
      </Table>
    </List>
  );
};
