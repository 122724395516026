import React from "react";
import { IResourceComponentsProps, BaseRecord, useMany } from "@refinedev/core";
import { useTable, List, EditButton, ShowButton, DateField, TagField, DeleteButton } from "@refinedev/antd";
import { Table, Space, Tooltip } from "antd";

export const AssessmentList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="title" title="Title" />
        <Table.Column dataIndex={["course", "title"]} title="Course" />
        <Table.Column
          dataIndex="prerequisites"
          title="Prerequisites"
          render={(value: any[]) => (
            <>
              {value?.map((item, index) => (
                <TagField key={index} value={item?.title} />
              ))}
            </>
          )}
        />
        <Table.Column dataIndex="duration" title="Duration" />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              {/* <EditButton hideText size="small" recordItemId={record._id} />
              <ShowButton hideText size="small" recordItemId={record._id} /> */}
              <Tooltip title="Delete">
                <DeleteButton hideText size="small" recordItemId={record._id} />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
