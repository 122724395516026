import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA-pzShdh4AlyD4kMtSz2F8QJXkDIy3H_k",
  authDomain: "nomadic-coast-307006.firebaseapp.com",
  projectId: "nomadic-coast-307006",
  storageBucket: "gs://nomadic-coast-307006.appspot.com",
  messagingSenderId: "702593440674",
  appId: "1:702593440674:web:6e4e64dd92f0c80edbf0fb",
  measurementId: "G-ZNK8GJ70G7"
};


const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
export default storage;