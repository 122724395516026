import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, DatePicker, Space, Button } from "antd";
import dayjs from "dayjs";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

export const QuestionCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const { selectProps: assessmentSelectProps } = useSelect({
    resource: "assessments/autocomplete",
    optionLabel: "label",
    optionValue: "value",

    pagination: {
      mode: "server"
    }
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Assessment"
          name={["assessment"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...assessmentSelectProps} />
        </Form.Item>
        <Form.Item
          label="Question"
          name={["query"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea autoSize rows={5} />
        </Form.Item>
        <Form.List name="choices">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    label="Choice"
                    name={[name]}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input.TextArea autoSize />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add choice
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item
          label="Answer"
          name={["answer"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea autoSize />
        </Form.Item>
      </Form>
    </Create>
  );
};
