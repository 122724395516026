import React from "react";
import { IResourceComponentsProps, BaseRecord, useMany } from "@refinedev/core";

import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DateField,
  useSelect,
  FilterDropdown,
  TextField,
  DeleteButton,
  ImageField,
  TagField,
} from "@refinedev/antd";

import { Table, Space, Select, Tooltip } from "antd";
import { IFee, IService, IServiceCategory } from "interfaces";

export const ServiceList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IService>({
    syncWithLocation: true
  });

  const { selectProps: categorySelectProps } = useSelect({
    resource: "services/categories",
    optionLabel: "title",
    optionValue: "title",

    pagination: {
      mode: "server"
    }
  });

  const { selectProps: feesSelectProps } = useSelect({
    resource: "fees",
    optionLabel: "title",
    optionValue: "title",

    pagination: {
      mode: "server"
    }
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex={["banner"]}
          title="Banner"
          render={(_, record: any) => (
            <ImageField value={record?.banner} width={100} />
          )}
        />
        <Table.Column dataIndex="title" title="Title" />
        <Table.Column dataIndex="description" title="Description" />
        <Table.Column dataIndex="requirements" title="Requirements" />
        <Table.Column
          dataIndex={["serviceCategory"]}
          title="Service Category"
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Select Category"
                {...categorySelectProps}
              />
            </FilterDropdown>
          )}
          render={(_, record: BaseRecord) =>
            record?.serviceCategory?.map((item: any) => (
              <>
                <TextField value={item?.title} />{" "}
              </>
            ))
          }
        />
        <Table.Column
          dataIndex={["serviceFee", "title"]}
          title="Service Fee"
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Select Category"
                {...feesSelectProps}
              />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex={["minSalary"]}
          title="Min Salary"
          render={(value: any) => <TextField value={value} />}
          width={100}
        />
        <Table.Column dataIndex="salaryRange" title="Salary Range" />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <Tooltip title="Show">
                <ShowButton hideText size="small" recordItemId={record._id} />
              </Tooltip>
              <Tooltip title="Edit">
                <EditButton hideText size="small" recordItemId={record._id} />
              </Tooltip>
              <Tooltip title="Delete">
                <DeleteButton hideText size="small" recordItemId={record._id} />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
