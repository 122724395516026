import React from "react";
import { IResourceComponentsProps, BaseRecord } from "@refinedev/core";

import {
  useTable,
  List,
  EditButton,
  ShowButton,
  useSelect,
  FilterDropdown,
  DeleteButton,
} from "@refinedev/antd";

import { Table, Space, Tooltip, Select } from "antd";
import { ICourse } from "../../interfaces";

import { VideoCameraOutlined } from "@ant-design/icons";

export const UnitList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const { selectProps: coursesSelectProps } = useSelect({
    resource: "courses/autocomplete",
    optionLabel: "label",
    optionValue: "value",

    pagination: {
      mode: "server",
    },
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="title" title="Title" />
        <Table.Column
          dataIndex={["course", "title"]}
          title="Course"
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Select Course"
                {...coursesSelectProps}
              />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="video"
          title="Video"
          render={() => {
            return (
              <Space>
                <VideoCameraOutlined />
                <span>1</span>
              </Space>
            );
          }}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <Tooltip title="Show">
                <ShowButton hideText size="small" recordItemId={record._id} />
              </Tooltip>
              {/* <Tooltip title="Edit">
                <EditButton hideText size="small" recordItemId={record._id} />
              </Tooltip> */}
              <Tooltip title="Delete">
                <DeleteButton hideText size="small" recordItemId={record._id} />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
