import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { DataProvider, HttpError } from "@refinedev/core";
import { stringify } from "query-string";
import { awaitGetLocalStorage } from "utils/awaitGetLocalStorage";

const axiosInstance = axios.create({
  baseURL: "https://api.maidsofhonour.africa",
  // baseURL: "http://100.124.212.1:4000",
  // baseURL: "http://localhost:4000",
});

axiosInstance.interceptors.request.use(
  async (request: AxiosRequestConfig) => {
    if (request.headers) {
      if (request.headers["refresh"]) {
        const refreshToken = await awaitGetLocalStorage("refreshToken");
        request.headers["Authorization"] = `Bearer ${refreshToken}`;
      } else {
        const token = await awaitGetLocalStorage("accessToken");
        request.headers["Authorization"] = `Bearer ${token}`;
      }
    } else {
      const token = await awaitGetLocalStorage("accessToken");
      request.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    return request;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);

export const RestDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
): DataProvider => ({
  create: async ({ resource, variables }) => {
    const url = `${apiUrl}/${resource}`;

    const { data } = await httpClient.post(url, variables);

    return {
      ...data,
    };
  },

  deleteOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.delete(url);

    return {
      ...data,
    };
  },

  getList: async ({ resource, pagination, filters }) => {
    const url = `${apiUrl}/${resource}`;

    const current = pagination?.current || 1;
    const pageSize = pagination?.pageSize || 10;

    const query = pagination
      ? {
          limit: current * pageSize - (current - 1) * pageSize,
          offset: (current - 1) * pageSize,
        }
      : {};

    if (filters) {
      filters.forEach((filter) => {
        if (
          filter.operator !== "or" &&
          filter.operator !== "and" &&
          "field" in filter
        ) {
          Object.assign(query, { [filter.field]: filter.value });
        } else {
          // Handle your conditional filters here
          // console.log(typeof filter); // ConditionalFilter
        }
      });
    }

    const data: any = await httpClient.get(`${url}?${stringify(query)}`);

    const {
      data: {
        data: { rows, count },
      },
    } = data;

    return {
      data: rows,
      total: count,
    };
  },

  getOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.get(url);

    return {
      ...data,
    };
  },
  update: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.patch(url, variables);

    return {
      ...data,
    };
  },
  // custom: ({
  //     url,
  //     method,
  //     sort,
  //     filters,
  //     payload,
  //     query,
  //     headers,
  //     metaData,
  // }) => Promise,
  getApiUrl: () => apiUrl,
});
