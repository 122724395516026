import React from "react";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { Show, TagField, TextField, DateField, NumberField, ImageField } from "@refinedev/antd";
import { Typography } from "antd";

const { Title } = Typography;

export const ServiceShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Banner</Title>
      <ImageField value={record?.banner} width={200} />
      <Title level={5}>Title</Title>
      <TextField value={record?.title} />
      <Title level={5}>Description</Title>
      <TextField value={record?.description} />
      <Title level={5}>Requirements</Title>
      <TextField value={record?.requirements} />
      <Title level={5}>Service Category</Title>
      <TextField value={record?.serviceCategory.type} />
      <Title level={5}>Service Fee</Title>
      <TextField value={record?.serviceFee.type} />
      <Title level={5}>Min Salary</Title>
      <TextField value={record?.minSalary} />
      <Title level={5}>Salary Range</Title>
      <TextField value={record?.salaryRange} />
      <Title level={5}>Created At</Title>
      <DateField value={record?.createdAt} />
      <Title level={5}>Updated At</Title>
      <DateField value={record?.updatedAt} />
    </Show>
  );
};
