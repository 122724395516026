import React from "react";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { Show, TagField, TextField, MarkdownField, DateField, NumberField } from "@refinedev/antd";
import { Typography } from "antd";

const { Title } = Typography;

export const UnitShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Course</Title>
      <TextField value={record?.course?.title} />
      <Title level={5}>Title</Title>
      <TextField value={record?.title} />
      <Title level={5}>Video</Title>
      {record?.video && (
        <video width="400" controls>
          <source src={record.video} type="video/mp4" />
        </video>
      )}
      <Title level={5}>Created At</Title>
      <DateField value={record?.createdAt} />
      <Title level={5}>Updated At</Title>
      <DateField value={record?.updatedAt} />
    </Show>
  );
};
