import React from "react";
import { IResourceComponentsProps, BaseRecord } from "@refinedev/core";
import { useTable, List, EditButton, ShowButton, DateField, DeleteButton } from "@refinedev/antd";
import { Table, Space, Tooltip } from "antd";

export const FeesList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="title" title="Title" />
        <Table.Column dataIndex="amount" title="Amount" />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <Tooltip title="Show">
                <ShowButton hideText size="small" recordItemId={record._id} />
              </Tooltip>
              <Tooltip title="Edit">
                <EditButton hideText size="small" recordItemId={record._id} />
              </Tooltip>
              <Tooltip title="Delete">
                <DeleteButton hideText size="small" recordItemId={record._id} />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
