import React from "react";
import { IResourceComponentsProps, useApiUrl, useNavigation } from "@refinedev/core";
import { Create, useForm, getValueFromEvent, useSelect } from "@refinedev/antd";
import { Form, Input, Select, DatePicker, SelectProps, Upload, Button } from "antd";
import dayjs from "dayjs";
import { IRole, IUser } from "interfaces";
import { awaitGetLocalStorage } from "utils/awaitGetLocalStorage";
import { UploadOutlined } from "@ant-design/icons";
import "./upload.css";
import { handleImageUpload } from "utils/handleImageUpload";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, onFinish } = useForm<IUser>({
    resource: "auth/signup/invite",
    redirect: "list",
  });

  const { selectProps: roleSelectProps } = useSelect({
    resource: "roles",
    optionLabel: "title",
    optionValue: "title",

    pagination: {
      mode: "server"
    }
  });

  const { list } = useNavigation();
  const apiUrl = useApiUrl();

  const handleOnFinish = async (values: any) => {
    const deviceId = await awaitGetLocalStorage("deviceId");
    await onFinish({
      ...values,
      dob: dayjs(values.dob).toDate().toISOString(),
      idNo: parseInt(values.idNo),
      yearsOfExperience: parseInt(values.yearsOfExperience),
      prevSalary: parseInt(values.prevSalary),
      deviceId,
      isInvite: true,
      role: values.role.title,
      avatar: values.avatar ? values.avatar[0].response : "",
      idFront: values.idFront ? values.idFront[0].response : "",
      idBack: values.idBack ? values.idBack[0].response : "",
      conductCert: values.conductCert ? values.conductCert[0].response : "",
    });
    list("users");
  };

  const skillOptions: SelectProps["options"] = [
    { label: "House Manager", value: "63f3293d3c3ac9f4a6b08a3a" },
    { label: "Professional Chef", value: "63f48c1bb8d2a4d187d31f94" },
    { label: "Nanny", value: "63f48262b8d2a4d187d31f3a" },
    { label: "Gardener", value: "63f494fab8d2a4d187d3200a" },
    { label: "Driver", value: "63f49450b8d2a4d187d31ff0" },
    { label: "Butler", value: "63f4927cb8d2a4d187d31fc6" },
    { label: "Special Needs Care", value: "63f47fbcb8d2a4d187d31f2b" },
    { label: "Home Nurse", value: "63f479fdb8d2a4d187d31f0c" },
    { label: "Elderly Care", value: "63f47e31b8d2a4d187d31f1b" },
    { label: "Home Nurse", value: "63f479fdb8d2a4d187d31f0c" },
    { label: "Childminder", value: "63f361a6b8d2a4d187d31da0'" },
    { label: "General Chef", value: "63f48cd6b8d2a4d187d31fae" },
    { label: "New Born Care", value: "63f48a81b8d2a4d187d31f6a" },
    { label: "New Born Mother Care", value: "63f48a81b8d2a4d187d31f6a" },
    // { label: "Pet Care", value: "Pet Care" },
  ];

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" onFinish={handleOnFinish}>
        <Form.Item label="Profile">
          <Form.Item
            name="avatar"
            valuePropName="avatar"
            getValueFromEvent={getValueFromEvent}
            noStyle
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Upload.Dragger
              name="profile"
              action={`${apiUrl}/storage/upload/image`}
              listType="picture"
              className="upload-list-inline"
              customRequest={handleImageUpload}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Upload Avatar</Button>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        {/* <Form.Item
          label="Profile"
          name="avatar"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item
          label="Role"
          name={["role", "title"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...roleSelectProps} />
        </Form.Item>
        <Form.Item
          label="First Name"
          name={["firstName"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name={["lastName"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name={["email"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
          label="Email Verified"
          valuePropName="checked"
          name={["emailVerified"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox>Verify User</Checkbox>
        </Form.Item> */}
        <Form.Item
          label="Phone"
          name={["phone"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Bio"
          name={["bio"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="ID Front">
          <Form.Item
            name="idFront"
            valuePropName="idFront"
            getValueFromEvent={getValueFromEvent}
            noStyle
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Upload.Dragger
              name="idFront"
              action={`${apiUrl}/storage/upload/image`}
              listType="picture"
              className="upload-list-inline"
              customRequest={handleImageUpload}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Upload Front</Button>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item label="ID Back">
          <Form.Item
            name="idBack"
            valuePropName="idBack"
            getValueFromEvent={getValueFromEvent}
            noStyle
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Upload.Dragger
              name="idBack"
              action={`${apiUrl}/storage/upload/image`}
              listType="picture"
              className="upload-list-inline"
              customRequest={handleImageUpload}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Upload Back</Button>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item label="Code of Conduct Certificate">
          <Form.Item
            name="conductCert"
            valuePropName="conductCert"
            getValueFromEvent={getValueFromEvent}
            noStyle
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Upload.Dragger
              name="conductCert"
              action={`${apiUrl}/storage/upload/image`}
              listType="picture"
              className="upload-list-inline"
              customRequest={handleImageUpload}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Upload Certificate</Button>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="Date of Birth"
          name={["dob"]}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Level of Education"
          name={["educationLevel"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Gender"
          name={["gender"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ID Number"
          name={["idNo"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Skills"
          name={["skills"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select mode="multiple" options={skillOptions} />
        </Form.Item>
        <Form.Item
          label="Previous Training"
          name={["previousTraining"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Work Arrangement"
          name={["workArrangement"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Years of Experience"
          name={["yearsOfExperience"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Place of residence"
          name={["placeOfResidence"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Previous Employer"
          name={["prevEmployer"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Previous Job Title"
          name={["prevJobDesc"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Previous Salary"
          name={["prevSalary"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
