import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, DatePicker } from "antd";
import dayjs from "dayjs";

export const AssessmentCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const { selectProps: courseSelectProps } = useSelect({
    resource: "courses/autocomplete",
    optionLabel: "label",
    optionValue: "value",

    pagination: {
      mode: "server"
    }
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Title"
          name={["title"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Course"
          name={["course"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...courseSelectProps} />
        </Form.Item>
        <Form.Item
          label="Duration"
          name={["duration"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Prerequisite Courses"
          name={["prerequisites"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select mode="multiple" {...courseSelectProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};
