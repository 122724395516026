import React from "react";
import { IResourceComponentsProps, useApiUrl } from "@refinedev/core";
import { Create, useForm, useSelect, getValueFromEvent } from "@refinedev/antd";
import { Form, Input, DatePicker, Select, Checkbox, Space, Button, Upload } from "antd";
import dayjs from "dayjs";
import { IFee } from "interfaces";
import {
  PlusOutlined,
  MinusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { handleVideoUpload } from "utils/handleVideoUpload";

export const CourseCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  const { selectProps: feeSelectProps } = useSelect({
    resource: "fees/autocomplete",
    optionLabel: "label",
    optionValue: "value",

    pagination: {
      mode: "server"
    }
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Title"
          name={["title"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea autoSize />
        </Form.Item>
        <Form.Item
          label="Type"
          name={["type"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              { label: "Mentorship", value: "Mentorship" },
              { label: "Training", value: "Training" },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Fee"
          name={"fee"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...feeSelectProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};
