import React from "react";
import { IResourceComponentsProps, BaseRecord, useMany } from "@refinedev/core";

import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DateField,
  MarkdownField,
  BooleanField,
  TagField,
  TextField,
  DeleteButton,
} from "@refinedev/antd";

import { Table, Space, Tag, Button, Tooltip } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

export const CourseList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex={["title"]}
          title="Title"
          render={(value: any) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="description"
          title="Description"
          render={(value: any) => <MarkdownField value={value} />}
        />
        <Table.Column dataIndex="type" title="Type" />
        <Table.Column dataIndex={["fee", "title"]} title="Fee" />
        <Table.Column
          dataIndex={["isActive"]}
          title="Is Active"
          render={(value: any) => (
            <Space size={[0, 8]} wrap>
              {value ? (
                <Tag color="#87d068">Active</Tag>
              ) : (
                <Tag color="#F50">Inactive</Tag>
              )}
            </Space>
          )}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <Tooltip title={record?.isActive ? "Disable" : "Activate"}>
                <Button
                  type="default"
                  size="small"
                  icon={
                    record?.isActive ? <CloseOutlined /> : <CheckOutlined />
                  }
                  // onClick={toggleCourseStatus} TODO: Implement toggleCourseStatus
                />
              </Tooltip>
              <Tooltip title="Show">
                <ShowButton hideText size="small" recordItemId={record._id} />
              </Tooltip>
              <Tooltip title="Edit">
                <EditButton hideText size="small" recordItemId={record._id} />
              </Tooltip>
              <Tooltip title="Delete">
                <DeleteButton hideText size="small" recordItemId={record._id} />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
