import React from "react";
import { IResourceComponentsProps, BaseRecord, useMany } from "@refinedev/core";
import { useTable, List, EditButton, ShowButton, TagField, DateField, DeleteButton } from "@refinedev/antd";
import { Table, Space, Tooltip } from "antd";

export const QuestionList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex={["assessment", "title"]} title="Assessment" />
        <Table.Column dataIndex="query" title="Query" />
        <Table.Column
          dataIndex="choices"
          title="Choices"
          render={(value: any[]) => (
            <>
              {value?.map((item) => (
                <TagField value={item} key={item} />
              ))}
            </>
          )}
        />
        <Table.Column dataIndex="answer" title="Answer" />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              {/* <EditButton hideText size="small" recordItemId={record._id} />
              <ShowButton hideText size="small" recordItemId={record._id} /> */}
              <Tooltip title="Delete">
                <DeleteButton hideText size="small" recordItemId={record._id} />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
