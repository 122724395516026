import { Authenticated, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
  ErrorComponent,
  notificationProvider,
  ThemedLayout,
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";

import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { BrowserRouter, Link, Outlet, Route, Routes } from "react-router-dom";
import { ColorModeContextProvider } from "./contexts/color-mode";

import { AntdInferencer } from "@refinedev/inferencer/antd";
import { Login } from "./pages/auth";
import {
  AssessmentCreate,
  AssessmentList,
  BookingList,
  CourseCreate,
  CourseEdit,
  CourseList,
  CourseShow,
  FeesCreate,
  FeesEdit,
  FeesList,
  FeesShow,
  QuestionCreate,
  QuestionList,
  RoleList,
  ServiceCategoryCreate,
  ServiceCategoryList,
  ServiceCategoryShow,
  ServiceCreate,
  ServiceEdit,
  ServiceList,
  ServiceShow,
  UnitCreate,
  UnitEdit,
  UnitList,
  UserCreate,
  UserEdit,
  UserList,
  UserShow,
} from "./pages";
import { authProvider, dataProvider } from "./providers";
import { Avatar, Space } from "antd";
import {
  BookOutlined,
  ControlOutlined,
  EditOutlined,
  FormOutlined,
  LockOutlined,
  ShopOutlined,
  SolutionOutlined,
  TeamOutlined,
  TransactionOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { UnitShow } from "./pages/units/show";
import { Header } from "./components";

function App() {
  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <Refine
            notificationProvider={notificationProvider}
            authProvider={authProvider()}
            routerProvider={routerBindings}
            dataProvider={dataProvider("https://api.maidsofhonour.africa")}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
            resources={[
              {
                name: "Users",
                icon: (
                  <Space>
                    <UserOutlined style={{ fontSize: "1.3rem" }} />
                  </Space>
                ),
              },

              {
                name: "users",
                list: "/users",
                show: "/users/show/:id",
                create: "/users/create",
                edit: "/users/edit/:id",
                meta: {
                  parent: "Users",
                  label: "Accounts",
                  icon: (
                    <Space>
                      <LockOutlined style={{ fontSize: "1.3rem" }} />
                    </Space>
                  ),
                },
              },
              {
                name: "roles",
                list: "/roles",
                show: "/roles/show/:id",
                create: "/roles/create",
                edit: "/roles/edit/:id",
                meta: {
                  parent: "Users",
                  label: "Roles",
                  icon: (
                    <Space>
                      <TeamOutlined style={{ fontSize: "1.3rem" }} />
                    </Space>
                  ),
                },
              },
              {
                name: "Services",
                icon: (
                  <Space>
                    <ControlOutlined style={{ fontSize: "1.3rem" }} />
                  </Space>
                ),
              },
              {
                name: "services",
                list: "/services",
                show: "/services/show/:id",
                create: "/services/create",
                edit: "/services/edit/:id",
                meta: {
                  parent: "Services",
                  label: "Services",
                  icon: (
                    <Space>
                      <SolutionOutlined style={{ fontSize: "1.3rem" }} />
                    </Space>
                  ),
                },
              },
              {
                name: "services/categories",
                list: "/services/categories",
                show: "/services/categories/show/:id",
                create: "/services/categories/create",
                edit: "/services/categories/edit/:id",
                meta: {
                  parent: "Services",
                  label: "Categories",
                  icon: (
                    <Space>
                      <SolutionOutlined style={{ fontSize: "1.3rem" }} />
                    </Space>
                  ),
                },
              },
              {
                name: "bookings",
                list: "/bookings",
                show: "/bookings/show/:id",
                create: "/bookings/create",
                edit: "/bookings/edit/:id",
                meta: {
                  icon: (
                    <Space>
                      <ShopOutlined style={{ fontSize: "1.3rem" }} />
                    </Space>
                  ),
                },
              },
              {
                name: "Courses",
                icon: (
                  <Space>
                    <BookOutlined style={{ fontSize: "1.3rem" }} />
                  </Space>
                ),
              },
              {
                name: "courses",
                list: "/courses",
                show: "/courses/show/:id",
                create: "/courses/create",
                edit: "/courses/edit/:id",
                meta: {
                  parent: "Courses",
                  label: "Courses",
                  icon: (
                    <Space>
                      <BookOutlined style={{ fontSize: "1.3rem" }} />
                    </Space>
                  ),
                },
              },
              {
                name: "courses/units",
                list: "/courses/units",
                show: "/courses/units/show/:id",
                create: "/courses/units/create",
                edit: "/courses/units/edit/:id",
                meta: {
                  parent: "Courses",
                  label: "Units",
                  icon: (
                    <Space>
                      <VideoCameraOutlined style={{ fontSize: "1.3rem" }} />
                    </Space>
                  ),
                },
              },
              {
                name: "Assessments",
                icon: (
                  <Space>
                    <FormOutlined style={{ fontSize: "1.3rem" }} />
                  </Space>
                ),
              },
              {
                name: "assessments",
                list: "/assessments",
                show: "/assessments/show/:id",
                create: "/assessments/create",
                edit: "/assessments/edit/:id",
                meta: {
                  parent: "Assessments",
                  label: "Assessments",
                  icon: (
                    <Space>
                      <FormOutlined style={{ fontSize: "1.3rem" }} />
                    </Space>
                  ),
                },
              },
              {
                name: "assessments/questions",
                list: "/assessments/questions",
                show: "/assessments/questions/show/:id",
                create: "/assessments/questions/create",
                edit: "/assessments/questions/edit/:id",
                meta: {
                  parent: "Assessments",
                  label: "Questions",
                  icon: (
                    <Space>
                      <EditOutlined style={{ fontSize: "1.3rem" }} />
                    </Space>
                  ),
                },
              },
              {
                name: "fees",
                list: "/fees",
                show: "/fees/show/:id",
                create: "/fees/create",
                edit: "/fees/edit/:id",
                meta: {
                  icon: (
                    <Space>
                      <TransactionOutlined style={{ fontSize: "1.3rem" }} />
                    </Space>
                  ),
                },
              },
            ]}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
              </Route>
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                    <ThemedLayout
                      Header={() => <Header />}
                      Title={({ collapsed }) => (
                        <Link
                          to="/"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {collapsed ? (
                            <Avatar
                              src="https://res.cloudinary.com/de3eoq2qb/image/upload/v1667578235/logo_irafkx.jpg"
                              alt="Refine"
                              size={"default"}
                            />
                          ) : (
                            <Avatar
                              src="https://res.cloudinary.com/de3eoq2qb/image/upload/v1667578235/logo_irafkx.jpg"
                              alt="Refine"
                              size={"large"}
                            />
                          )}
                        </Link>
                      )}
                    >
                      <Outlet />
                    </ThemedLayout>
                  </Authenticated>
                }
              >
                <Route
                  index
                  element={<NavigateToResource resource="users" />}
                />
                <Route path="users">
                  <Route index element={<UserList />} />
                  <Route path="show/:id" element={<UserShow />} />
                  <Route path="edit/:id" element={<UserEdit />} />
                  <Route path="create" element={<UserCreate />} />
                </Route>
                <Route path="roles">
                  <Route index element={<RoleList />} />
                  <Route path="show/:id" element={<AntdInferencer />} />
                  <Route path="edit/:id" element={<AntdInferencer />} />
                  <Route path="create" element={<AntdInferencer />} />
                </Route>
                <Route path="services">
                  <Route index element={<ServiceList />} />
                  <Route path="show/:id" element={<ServiceShow />} />
                  <Route path="edit/:id" element={<ServiceEdit />} />
                  <Route path="create" element={<ServiceCreate />} />
                  Show
                </Route>
                <Route path="services/categories">
                  <Route index element={<ServiceCategoryList />} />
                  <Route path="show/:id" element={<ServiceCategoryShow />} />
                  <Route path="edit/:id" element={<AntdInferencer />} />
                  <Route path="create" element={<ServiceCategoryCreate />} />
                </Route>
                <Route path="bookings">
                  <Route index element={<BookingList />} />
                  <Route path="show/:id" element={<AntdInferencer />} />
                  <Route path="edit/:id" element={<AntdInferencer />} />
                  <Route path="create" element={<AntdInferencer />} />
                </Route>
                <Route path="courses">
                  <Route index element={<CourseList />} />
                  <Route path="show/:id" element={<CourseShow />} />
                  <Route path="edit/:id" element={<CourseEdit />} />
                  <Route path="create" element={<CourseCreate />} />
                </Route>
                <Route path="courses/units">
                  <Route index element={<UnitList />} />
                  <Route path="show/:id" element={<UnitShow />} />
                  <Route path="edit/:id" element={<UnitEdit />} />
                  <Route path="create" element={<UnitCreate />} />
                </Route>
                <Route path="assessments">
                  <Route index element={<AssessmentList />} />
                  <Route path="show/:id" element={<AntdInferencer />} />
                  <Route path="edit/:id" element={<AntdInferencer />} />
                  <Route path="create" element={<AssessmentCreate />} />
                </Route>
                <Route path="assessments/questions">
                  <Route index element={<QuestionList />} />
                  <Route path="show/:id" element={<AntdInferencer />} />
                  <Route path="edit/:id" element={<AntdInferencer />} />
                  <Route path="create" element={<QuestionCreate />} />
                </Route>
                <Route path="fees">
                  <Route index element={<FeesList />} />
                  <Route path="show/:id" element={<FeesShow />} />
                  <Route path="edit/:id" element={<FeesEdit />} />
                  <Route path="create" element={<FeesCreate />} />
                </Route>
                <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>
            <RefineKbar />
            <UnsavedChangesNotifier />
          </Refine>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
