import React from "react";
import { IResourceComponentsProps, useApiUrl } from "@refinedev/core";
import { Edit, useForm, getValueFromEvent } from "@refinedev/antd";
import { Form, Input, Checkbox, Upload, Button } from "antd";
import { IUser } from "interfaces";
import { handleImageUpload } from "utils/handleImageUpload";
import { UploadOutlined } from "@ant-design/icons";

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, onFinish } =
    useForm<IUser>();
  const apiUrl = useApiUrl();

  const usersData = queryResult?.data;

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        {/* <Form.Item label="Profile">
          <Form.Item
            name="avatar"
            valuePropName="avatar"
            getValueFromEvent={getValueFromEvent}
            noStyle
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Upload.Dragger
              name="profile"
              action={`${apiUrl}/storage/upload/image`}
              listType="picture"
              className="upload-list-inline"
              customRequest={handleImageUpload}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Upload Avatar</Button>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item> */}
        <Form.Item
          label="Profile"
          name="avatar"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="First Name"
          name={"firstName"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name={"lastName"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name={"email"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone"
          name={"phone"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="ID Front">
          <Form.Item
            name="idFront"
            valuePropName="idFront"
            getValueFromEvent={getValueFromEvent}
            noStyle
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Upload.Dragger
              name="idFront"
              action={`${apiUrl}/storage/upload/image`}
              listType="picture"
              className="upload-list-inline"
              customRequest={handleImageUpload}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Upload Front</Button>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item label="ID Back">
          <Form.Item
            name="idBack"
            valuePropName="idBack"
            getValueFromEvent={getValueFromEvent}
            noStyle
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Upload.Dragger
              name="idBack"
              action={`${apiUrl}/storage/upload/image`}
              listType="picture"
              className="upload-list-inline"
              customRequest={handleImageUpload}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Upload Back</Button>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item label="Code of Conduct Certificate">
          <Form.Item
            name="conductCert"
            valuePropName="conductCert"
            getValueFromEvent={getValueFromEvent}
            noStyle
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Upload.Dragger
              name="conductCert"
              action={`${apiUrl}/storage/upload/image`}
              listType="picture"
              className="upload-list-inline"
              customRequest={handleImageUpload}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Upload Certificate</Button>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Edit>
  );
};
