import { awaitGetLocalStorage } from "./awaitGetLocalStorage";
import axios from "axios";

export const handleVideoUpload = async (info: any) => {
  const token = await awaitGetLocalStorage("accessToken");

  const { file, filename, onSuccess } = info;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", filename);

  const response = await axios.post(
    "https://api.maidsofhonour.africa/storage/upload/video",
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  onSuccess(response.data.data.url, file);
};
