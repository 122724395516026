import React from "react";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { BooleanField, DateField, Show, TextField } from "@refinedev/antd";
import { Tag, Typography } from "antd";

const { Title } = Typography;

export const CourseShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <TextField value={record?.category?.type} />
      <Title level={5}>Title</Title>
      <TextField value={record?.title} />
      <Title level={5}>Description</Title>
      <Typography.Text>{record?.description}</Typography.Text>
      <Title level={5}>Type</Title>
      <TextField value={record?.type} />
      <Title level={5}>Fee</Title>
      <TextField value={record?.fee?.type} />
      <Title level={5}>Active</Title>
      {record?.isActive ? (
        <Tag color="#87d068">Active</Tag>
      ) : (
        <Tag color="#F50">Inactive</Tag>
      )}
      <Title level={5}>Created At</Title>
      <DateField value={record?.createdAt} />
      <Title level={5}>Updated At</Title>
      <DateField value={record?.updatedAt} />
    </Show>
  );
};
