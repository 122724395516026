import React from "react";
import { IResourceComponentsProps, useApiUrl, useNavigation } from "@refinedev/core";
import { Create, useForm, useSelect, getValueFromEvent } from "@refinedev/antd";
import { Form, Input, DatePicker, Select, Upload, Button } from "antd";
import dayjs from "dayjs";
import { IFee, IServiceCategory } from "interfaces";
import { handleImageUpload } from "../../utils/handleImageUpload";
import { UploadOutlined } from "@ant-design/icons";
import { awaitGetLocalStorage } from "../../utils/awaitGetLocalStorage";

export const ServiceCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, onFinish } = useForm();

  const { selectProps: categorySelectProps } = useSelect({
    resource: "services/categories/autocomplete",
    optionLabel: "label",
    optionValue: "value",

    pagination: {
      mode: "server"
    }
  });

  const { selectProps: feesSelectProps } = useSelect({
    resource: "fees/autocomplete",
    optionLabel: "label",
    optionValue: "value",

    pagination: {
      mode: "server"
    }
  });

  const { list } = useNavigation();
  const apiUrl = useApiUrl();

  const handleOnFinish = async (values: any) => {
    await onFinish({
      ...values,
      banner: values.banner ? values.banner[0].response : "",
    });
    list("services");
  };

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" onFinish={handleOnFinish}>
        <Form.Item label="Banner">
          <Form.Item
            name="banner"
            valuePropName="banner"
            getValueFromEvent={getValueFromEvent}
            noStyle
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Upload.Dragger
              name="banner"
              action={`${apiUrl}/storage/upload/image`}
              listType="picture"
              className="upload-list-inline"
              customRequest={handleImageUpload}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Upload banner</Button>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="Category"
          name={["serviceCategory"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...categorySelectProps}
            placeholder="Select Category"
            mode="multiple"
          />
        </Form.Item>
        <Form.Item
          label="Title"
          name={["title"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name={["description"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Requirements"
          name={["requirements"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Fee"
          name={["serviceFee"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...feesSelectProps} placeholder="Select Fee" />
        </Form.Item>
        <Form.Item
          label="Min Salary"
          name={["minSalary"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Salary Range"
          name={["salaryRange"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
