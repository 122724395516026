import React from "react";
import { IResourceComponentsProps, BaseRecord, useList } from "@refinedev/core";

import {
  useTable,
  List,
  ShowButton,
  TagField,
  EmailField,
  BooleanField,
  ImageField,
  useImport,
  ImportButton,
  CreateButton,
  RefreshButton,
  EditButton,
  DeleteButton,
} from "@refinedev/antd";

import { Table, Space, Tooltip, Spin, Tag } from "antd";
import { IUser } from "interfaces";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const deviceId = JSON.parse(localStorage.getItem("deviceId") || "");

  const { tableProps } = useTable<IUser>({
    syncWithLocation: true,
  });

  const importProps = useImport<IUser>({
    batchSize: 1,

    mapData: (item: any) => {
      let data: any = {};
      Object.keys(item).forEach((key) => {
        data[key] = item[key].trim();
      });
      return {
        dob: dayjs(data.dob),
        educationLevel: data.educationLevel,
        email: data.email,
        firstName: data.firstName,
        gender: data.gender,
        idNo: parseInt(data.idNo),
        lastName: data.lastName,
        phone: data.phone,
        deviceId,
        isInvite: true,
        role: "serviceProvider",
        placeOfResidence: data.placeOfResidence,
        prevEmployer: data.prevEmployer,
        prevJobDesc: data.prevJobDesc,
        prevSalary: parseInt(data.prevSalary),
        previousTraining: data.previousTraining,
        skills: data.skills.split(","),
        workArrangement: data.workArrangement,
        yearsOfExperience: parseInt(data.yearsOfExprerience),
      };
    },

    resource: "auth/signup/invite",
  });

  const csvData = [
    [
      "firstName",
      "lastName",
      "dender",
      "dob",
      "idNo",
      "phone",
      "email",
      "placeOfResidence",
      "educationLevel",
      "prevEmployer",
      "prevJobDesc",
      "prevSalary",
      "previousTraining",
      "skills",
      "workArrangement",
      "yearsOfExprerience",
    ],
  ];

  return (
    <List
      headerProps={{
        extra: (
          <Space>
            <CSVLink data={csvData} filename="Import SP template">
              Download import template
            </CSVLink>
            <CreateButton />
            <ImportButton {...importProps} />
            <RefreshButton />
          </Space>
        ),
      }}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex={["avatar"]}
          title="Profile"
          render={(_, record: any) => (
            <ImageField value={record?.avatar} width={100} />
          )}
        />
        <Table.Column dataIndex={["role", "title"]} title="Role" />
        <Table.Column dataIndex="firstName" title="First Name" />
        <Table.Column dataIndex="lastName" title="Last Name" />
        <Table.Column
          dataIndex={["email"]}
          title="Email"
          render={(value: any) => <EmailField value={value} />}
          width={300}
        />
        <Table.Column dataIndex="phone" title="Phone" />
        <Table.Column
          dataIndex="skills"
          title="Skills"
          render={(values: any) => {
            return values?.map((value: any) => (
              <>
                <TagField value={value.title} key={value._id} />
              </>
            ));
          }}
        />
        <Table.Column
          title="Prev. Employer"
          render={(_, record: any) => {
            return <PrevEmployerColumn record={record} />;
          }}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <Tooltip title="Show">
                <ShowButton hideText size="small" recordItemId={record._id} />
              </Tooltip>
              <Tooltip title="Edit">
                <EditButton hideText size="small" recordItemId={record._id} />
              </Tooltip>
              <Tooltip title="Delete">
                <DeleteButton hideText size="small" recordItemId={record._id} />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

const PrevEmployerColumn = ({ record }: any) => {
  const { data: employersData, isLoading: employersIsLoading } = useList({
    resource: `referrals/employers`,

    filters: [
      {
        field: "serviceProvider",
        operator: "eq",
        value: record._id,
      },
    ],
  });

  const employer = employersData?.data[0];

  return (
    <PrevEmployerTags
      employerData={employer}
      employerIsLoading={employersIsLoading}
    />
  );
};

const PrevEmployerTags = ({ employerIsLoading, employerData }: any) => {
  const { data: answersData, isLoading: answersIsLoading } = useList({
    resource: `referrals/employers/answers`,

    filters: [
      {
        field: "employer",
        operator: "eq",
        value: employerData?._id,
      },
    ],
  });

  const answer = answersData?.data[0];

  if (answersIsLoading || employerIsLoading) return <Spin />;

  if (answer && employerData) {
    return (
      <Space>
        <Tag color="green">Verified</Tag>
      </Space>
    );
  }

  if (employerData) {
    return (
      <Space>
        <Tag color="yellow">Pending</Tag>
      </Space>
    );
  }

  return (
    <Space>
      <Tag color="red">Unverified</Tag>
    </Space>
  );
};
