import { AuthBindings } from "@refinedev/core";
import axios, { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import { decodeToken } from "react-jwt";
import { awaitGetLocalStorage } from "utils/awaitGetLocalStorage";

const axiosInstance = axios.create({
  baseURL: "https://api.maidsofhonour.africa",
  // baseURL: "http://100.124.212.1:4000",
  // baseURL: "http://localhost:4000",
});

type AuthActionResponse = {
  success: boolean;
  redirectTo?: string;
  error?: Error;
  [key: string]: unknown;
};

type CheckResponse = {
  authenticated: boolean;
  redirectTo?: string;
  logout?: boolean;
  error?: Error;
};

type OnErrorResponse = {
  redirectTo?: string;
  logout?: boolean;
  error?: Error;
};

type PermissionResponse = unknown;

type IdentityResponse = unknown;

export const authProvider = (
  httpClient: AxiosInstance = axiosInstance
): AuthBindings => ({
  login: async ({ email, password }): Promise<AuthActionResponse> => {
    const deviceId = uuid();
    const user = await httpClient.post("/auth/login", {
      email,
      password,
      deviceId,
    });

    if (user) {
      const {
        data: {
          data: { accessToken, refreshToken },
        },
      } = user;

      const decoded: any = decodeToken(accessToken);

      const {
        data: { data },
      } = await httpClient.get(`/users/${decoded.sub}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      localStorage.setItem("auth", JSON.stringify(data));
      localStorage.setItem("accessToken", JSON.stringify(accessToken));
      localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
      localStorage.setItem("deviceId", JSON.stringify(deviceId));

      return {
        success: true,
        redirectTo: "/",
      };
    }

    return {
      success: false,
      error: {
        message: "Login Error",
        name: "Invalid email or password",
      },
    };
  },

  logout: async (): Promise<AuthActionResponse> => {
    const accessToken = await awaitGetLocalStorage("accessToken");
    const { data } = await httpClient.get("/auth/logout", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (data.success) {
      localStorage.removeItem("auth");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("deviceId");
      return {
        success: true,
        redirectTo: "/",
      };
    }

    return {
      success: false,
      error: {
        message: "Logout Error",
        name: "Logout Error",
      },
    };
  },

  check: async (): Promise<CheckResponse> => {
    return localStorage.getItem("auth")
      ? {
          authenticated: true,
        }
      : {
          authenticated: false,
          redirectTo: "/login",
          logout: true,
          error: {
            message: "Check failed",
            name: "Not authenticated",
          },
        };
  },

  onError: async (error): Promise<OnErrorResponse> => {
    if (error.status === 401) {
      console.log("401 error", error);
      return {
        redirectTo: "/login",
        logout: true,
        error: error,
      };
    }
    return {};
  },

  getPermissions: async (): Promise<PermissionResponse> => {
    const auth = JSON.parse(localStorage.getItem("auth") || "");
    if (auth) {
      const role = auth.role.title;
      const permissions = auth.role.permissions;
      return { role, permissions };
    }
    return null;
  },

  getIdentity: async () => {
    const user = localStorage.getItem("auth");

    if (user) {
      const { firstName, lastName, avatar } = JSON.parse(user);

      return { name: `${firstName} ${lastName}`, avatar };
    }
    return null;
  },
});
