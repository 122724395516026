// import { awaitGetLocalStorage } from "./awaitGetLocalStorage";
// import axios from "axios";
import storage from '../utils/firebaseClient';
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";

export const handleImageUpload = async (info: any) => {
  const { file, filename, onSuccess } = info;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", filename);
  // const token = await awaitGetLocalStorage("accessToken");
  const filePath = `profile/${file.name}`
  const storageRef = ref(storage, filePath);
  // upload image to firebase
  uploadBytes(storageRef, file).then((snapshot: any) => {
    // get uploaded image
    const bucketRef = ref(storage, snapshot.metadata.fullPath);
    getDownloadURL(bucketRef)
      .then(async (profileUrl: any) => {
        console.log(`****** User Profile ******`, profileUrl)
        // const response = await axios.post(
        //       "https://api.maidsofhonour.africa/storage/upload/image",
        //       formData,
        //       {
        //         headers: {
        //           Authorization: `Bearer ${token}`,
        //           "Content-Type": "multipart/form-data",
        //         },
        //       }
        //     );
        onSuccess(profileUrl, file);
      })
  });
};